import './style.css'
import './favicon.png'
import './72x72.png'
import './114x114.png'
// import './home.html'
// import './origins.html'
// import './hall.html'

class App {
  constructor() {
    this.pages = [
      'home',
      'hall',
      'origins'
    ]
    this.routes = {
      "404": {
                template: "/templates/404.html",
                title: "404",
                html: `PAGE NOT FOUND`
            },
      "/": {
          title: "Home of $GREG",
          html: ``,
      },
      "/origins": {
          template: ``,
          title: "Origins of $GREG",
          html: ``,
      },
      "/hall": {
          template: ``,
          title: "Hall of $GREG",
          html: ``,
      },
    }
    this.isHover = getComputedStyle(document.documentElement).getPropertyValue('--hover');
    this.isMenuOpen = false
    this.ini()
  }
  async ini() {
    await this.fetchPages()
    await this.getRandomHeroImg()
    this.locationHandler()
    this.checkClientColorScheme()
    this.addEventlisteners()
    this.removePreloader()
  }
  async getRandomHeroImg() {
    const randomImageNumber = Math.floor( Math.random() * (  15 - 1 ) + 1 )
    const response = await fetch( `img/greg-${randomImageNumber}.jpg` )
    const imageBlob = await response.blob()
    this.heroImageUrl = URL.createObjectURL( imageBlob )
  }
  removePreloader() {
    setTimeout(() => {
      document.querySelector( '.preloader' ).style.top = '100%'
      setTimeout(() => {
        document.querySelector( '.preloader' ).classList.remove('preloader-on')
      }, 600);
    }, 1640)
  }
  async fetchPages() {
    for( var page of this.pages ) {
      const response = await fetch(`${page}.html`).then( )
      const pageContent = await response.text()
      if( page === 'home' ) { page = '/' } else { page = '/' + page }
      this.routes[ page ].html = pageContent
    }
  }
  addEventlisteners() {    
    this.addBtnClickListener()
    this.addNavEventListener()
    this.addMenuEventListener()   
    window.addEventListener("popstate", e => {
      this.locationHandler()
    })
    window.addEventListener("hashchange", e => {
      this.locationHandler()
    })
  }
  addBtnClickListener() {
    const allBtns = document.querySelectorAll( '[ data-type=btn ]' )
    allBtns.forEach( btn => {
      if( btn.getAttribute('data-click-listener') ) {
        return
      } else {
        btn.addEventListener( 'click', ( e ) => this.clickEvent( e ))
        btn.setAttribute( 'data-click-listener', true )
      }
    })
  }
  addNavEventListener() {
    const navBtns = document.querySelectorAll( '[ data-btn-region=nav ]' )
    navBtns.forEach( event => { 
      if( event.getAttribute('data-mouseenter-listener') ) {
        return
      } else {
        event.addEventListener( 'mouseenter', ( e ) => {
          if( this.isHover === 'true') {
            e.target.setAttribute( 'data-hover-event' , true )
            navBtns.forEach( btn => {
              if( btn.getAttribute( 'data-hover-event') === 'false' ) {
                  btn.style.opacity = 0.3
              }
            }) 
          }
        })
        event.setAttribute( 'data-mouseenter-listener', true )
        
        event.addEventListener( 'mouseleave', ( e ) => {
          if( this.isHover === 'true' ) {
            
              navBtns.forEach( btn => {
                if( btn.getAttribute( 'data-hover-event') === 'false' ) {
                    btn.style.removeProperty( 'opacity' )
                }
              })
              e.target.setAttribute( 'data-hover-event' , false )
          }
        })
      }
    })
  }
  addMenuEventListener() {
    const menuBtns = document.querySelectorAll( '[ data-btn-region=menu ]' )
    menuBtns.forEach( event => {
      event.addEventListener( 'mouseenter', ( e ) => {
        e.target.setAttribute( 'data-hover-event' , true )
        menuBtns.forEach( btn => {
          if( btn.getAttribute( 'data-hover-event') === 'false' ) {
            btn.style.opacity = 0.3
          }
        }) 
      })
      event.addEventListener( 'mouseleave', ( e ) => {
        menuBtns.forEach( btn => {
          if( btn.getAttribute( 'data-hover-event') === 'false' ) {
            btn.style.removeProperty( 'opacity' )
          }
        })
        e.target.setAttribute( 'data-hover-event' , false )
      })
    })
  }
  addGalleryListeners() {
    this.imgs = document.querySelectorAll( '.img-thumb img' )
    this.imgSrcArray = []
    this.imgs.forEach ( img => {
      const src = img.getAttribute( 'src' )
      this.imgSrcArray.push( src )
    })
    const galleryBtns = document.querySelectorAll( '[data-btn-region=gallery]' )
    galleryBtns.forEach( btn => {
      btn.addEventListener( 'click', ( e ) => this.clickEvent( e ) )
    })
    const getStartPos = ( e ) => {
      const getEndPos = ( e ) => {
        const endPosX = e.changedTouches[0].clientX
        if( startPosX - endPosX > 30 ) {
          if( this.clickedIndex === this.imgSrcArray.length - 1) {
            return
          } else {
            this.clickedIndex++
            document.querySelector('.lightbox-img').src = this.imgSrcArray[this.clickedIndex]
          }
        } else if( startPosX - endPosX < -30 ) {
          if( this.clickedIndex === 0) {
            return
          } else {
            this.clickedIndex--
            document.querySelector('.lightbox-img').src = this.imgSrcArray[this.clickedIndex]
          }
        }
        document.querySelector('.lightbox-img').removeEventListener( 'touchend', getEndPos )
      }
      const startPosX = e.changedTouches[0].clientX
      document.querySelector('.lightbox-img').addEventListener( 'touchend', getEndPos )
    }
    document.querySelector('.lightbox-img').addEventListener( 'touchstart', ( e ) => getStartPos( e ) )

      
  }
  async clickEvent(e) {
    const event = e.target.getAttribute('data-action')
    // console.log(event)

    switch( event ) {
      //lightbox
      case 'prev':
        if( this.clickedIndex === 0 ){
          return
        } else {
          this.clickedIndex--
          document.querySelector('.lightbox-img').src = this.imgSrcArray[this.clickedIndex]
        }
        break
      case 'next':
        console.log(this.imgSrcArray.length, this.clickedIndex)
        if( this.clickedIndex === this.imgSrcArray.length - 1) {
          return
        } else {
          this.clickedIndex++
          document.querySelector('.lightbox-img').src = this.imgSrcArray[this.clickedIndex]
        }
        break
      case 'lightboxOn':
        e.preventDefault()
        document.querySelector( '.lightbox' ).classList.add( 'lightboxOn' )
        const clickedSrc = e.target.querySelector('img').getAttribute( 'src' )
        this.clickedIndex = this.imgSrcArray.indexOf( clickedSrc )
        document.querySelector('.lightbox-img').src = this.imgSrcArray[this.clickedIndex]
        break
      case 'lightboxOff':
        document.querySelector( '.lightbox' ).classList.remove( 'lightboxOn' )
        break
      //rest
      case 'copy':
        const textContent = e.target.closest( '.address-box' ).querySelector( '.__address' ).textContent
        navigator.clipboard.writeText( textContent )
        break
      case 'theme':
        this.toggleTheme()
        break
      case 'menu':
        this.toggleMenu()
        break
      case 'buy':
      case 'funds':
      case 'charity':
      case 'join':
        e.preventDefault()
        const section = document.querySelector(`#${event}`)
        if( section ) {
          section.scrollIntoView()
        } else {
          window.history.pushState({}, "", '/')
          this.locationHandler()
          this.addBtnClickListener()
          setTimeout( () => {
            document.querySelector(`#${event}`).scrollIntoView()
          }, 10)
        }
        break
      case 'origins':
        e.preventDefault()
        this.route( e )
        break
      case 'hall':
        e.preventDefault()
        this.route( e )
        break
      case 'home':
        e.preventDefault()
        this.route( e )
      break
      case 'download-memes':
        e.preventDefault()
        document.querySelector('.download-modal').style.display = 'block'
        setTimeout(() => {
          document.querySelector('.download-modal').classList.add( 'download-modal-on' )
        }, 4);
        break
      case 'download-modal-off':
        document.querySelector('.download-modal').classList.remove( 'download-modal-on' )
        setTimeout(() => {
          document.querySelector('.download-modal').style.removeProperty('display')
        }, 300);
        break
    }

    if( event !== 'menu' && event !== 'theme' && this.isMenuOpen ) {
      this.toggleMenu()
    }
  }
  checkClientColorScheme() {
    const matchMedia = window.matchMedia("(prefers-color-scheme: light)")
    if(!matchMedia.matches) {
      this.toggleTheme()
    }
  }
  toggleTheme() {
    if( document.querySelector( 'html' ).getAttribute( 'data-theme' ) === 'light' ) {
      document.querySelector( 'html' ).setAttribute( 'data-theme', 'dark' )
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#65A5FE')
    } 
    else {
      document.querySelector( 'html' ).setAttribute( 'data-theme', 'light' )
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#121923')
    }
  }
  toggleMenu() {
    const menu = document.querySelector('.menu')
    const menuBtn = document.querySelector( '[data-action=menu' )
    if( this.isMenuOpen ) {
      this.isMenuOpen = false
      menuBtn.textContent = 'Menu'
      menu.style.top = '100%'
    } else {
      this.isMenuOpen = true
      menuBtn.textContent = 'Close'
      menu.style.top = 'var(--nav-height)'
    }
  }
  route( event ) {
    event = event || window.event; // get window.event if event argument not provided
    event.preventDefault()
    // window.history.pushState(state, unused, target link);
    window.history.pushState({}, "", event.target.href)
    this.locationHandler()
  }
  async locationHandler() {
        var location = window.location.hash.replace( '#', '/') // get the url path
        // if the path length is 0, set it to primary page route
        if (location.length == 0) {
            location = "/";
        }
        // get the route object from the urlRoutes object
        const route = this.routes[location] || this.routes["/"]
        // get the html from the template
        const html = route.html
        //set scroll position
        window.scrollTo(0, 0)
        // set the content of the content div to the html
        document.querySelector('#app').innerHTML = html
        this.updateListener( location )
        if( location === '/' ) {
          document.querySelector( '.greg-hero').src = this.heroImageUrl
        }
        //link image blobs to injected divs
        // set the title of the document to the title of the route
        document.title = route.title
        // set the description of the document to the description of the route
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", route.description)
  }
  updateListener( location ) {
    switch( location ) {
      case '/':
        this.addBtnClickListener()
        break
      case '/origins':
        this.addBtnClickListener()
        break
      case '/hall':
        this.addBtnClickListener()
        this.addGalleryListeners()
    }
  }
}

new App()